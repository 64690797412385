<template>
  <div>
    <ClientsInfo />
    <ClientsWrp />
  </div>
</template>

<script>
  import ClientsInfo from '@/components/Clients/ClientsInfo'
  import ClientsWrp from '@/components/Clients/ClientsWrp'

  export default {
      components: {
        ClientsInfo, ClientsWrp
      },
      data() {
        return {
        }
      }
  }
    
</script>

<style scoped>

</style>