<template>
    <div class="clients__wrp">
        <div v-if="errors && errors.length">
            <div v-for="error of errors" :key="error.id">
                {{error.message}}
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody v-if="store.clients && store.clients.length">
                    <tr v-for="client of store.clients" :key="client.uk">
                        <td class="clients__name">{{client.t}}</td>
                        <td>
                            <button @click="showEditModal(client)">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 5L1.5 12.5V16.5H5.5L13 9M9 5L12.5 1.5L16.5 5.5L13 9M9 5L13 9" stroke="#606569" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </td>
                        <td>
                            <button  @click="removeClient(client)">
                                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5 5.99996C17.0523 5.99996 17.5 5.55224 17.5 4.99996C17.5 4.44767 17.0523 3.99996 16.5 3.99996V5.99996ZM1.5 3.99996C0.947715 3.99996 0.5 4.44767 0.5 4.99996C0.5 5.55224 0.947715 5.99996 1.5 5.99996V3.99996ZM12.3333 4.99996V5.99996H13.3333V4.99996H12.3333ZM5.66667 4.99996H4.66667V5.99996H5.66667V4.99996ZM3.16667 5.99996H14.8333V3.99996H3.16667V5.99996ZM13.8333 4.99996V16.6666H15.8333V4.99996H13.8333ZM13.1667 17.3333H4.83333V19.3333H13.1667V17.3333ZM4.16667 16.6666V4.99996H2.16667V16.6666H4.16667ZM14.8333 5.99996H16.5V3.99996H14.8333V5.99996ZM3.16667 3.99996H1.5V5.99996H3.16667V3.99996ZM4.83333 17.3333C4.46514 17.3333 4.16667 17.0348 4.16667 16.6666H2.16667C2.16667 18.1394 3.36057 19.3333 4.83333 19.3333V17.3333ZM13.8333 16.6666C13.8333 17.0348 13.5349 17.3333 13.1667 17.3333V19.3333C14.6394 19.3333 15.8333 18.1394 15.8333 16.6666H13.8333ZM7.33333 2.66663H10.6667V0.666626H7.33333V2.66663ZM11.3333 3.33329V4.99996H13.3333V3.33329H11.3333ZM12.3333 3.99996H5.66667V5.99996H12.3333V3.99996ZM6.66667 4.99996V3.33329H4.66667V4.99996H6.66667ZM10.6667 2.66663C11.0349 2.66663 11.3333 2.9651 11.3333 3.33329H13.3333C13.3333 1.86053 12.1394 0.666626 10.6667 0.666626V2.66663ZM7.33333 0.666626C5.86057 0.666626 4.66667 1.86053 4.66667 3.33329H6.66667C6.66667 2.9651 6.96514 2.66663 7.33333 2.66663V0.666626Z" fill="#606569"/>
                                </svg>
                            </button>
                        </td>
                    </tr>                        
                </tbody>
        </table>
        <div class="bg" v-if="showEditClientModal" @close="showEditClientModal = false"></div>
        <EditClientModal :clientRow="client" :editTitle="editTitle" v-if="showEditClientModal" @close="showEditClientModal = false"/>

    </div>
</template>

<script>
    import EditClientModal from '@/components/Modal/EditClientModal'
    import { useClientStore } from '@/store/ClientStore'
    import { EventBus } from '@/store/EventBus'
   
    export default {
        data() {
            const clientStore = useClientStore()
            return {
                showEditClientModal: false,
                client: [],
                editTitle: '',
                errors: [],
                store: clientStore
            }
        },
        components: {
            EditClientModal,
        },
        created() {
            this.store.listing()
        },
        methods: {
            showEditModal(client){
                this.showEditClientModal = !this.showEditClientModal;
                this.client = client;
                this.editTitle = client.t;
            },
            removeClient(client) {
                if ( confirm('Are you sure you want to remove client "' + client.t + '"' ) ) {
                    this.store.remove(client)
                }
            }
        }
    }
    const wlClientsHandler = function() {
        const clientStore = useClientStore();
        clientStore.listing()
        EventBus.$off('click', wlClientsHandler);
    }

    EventBus.$on('click', wlClientsHandler);
</script>



<style scoped>
    button{
        border: none;
        background: none;
        cursor: pointer;
        font-family: 'Montserrat', sans-serif;
        border: 2px solid #F2F3F6;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 42px;
    }

    .clients__wrp{
        color: #222222;
        padding: 10px 30px;
        min-width: 200px;
        overflow-x: auto;
        height: auto;
    }



    table{
        width: 100%;
        min-width: 300px;
        margin-bottom: 30px;
        background-color: #fff;
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.05);
    }

    th{
        padding: 16px 5px;
        text-align: left;
        border-bottom: 1px solid #F2F3F6;
        color: #606569;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;

    }

    th:first-child,
    td:first-child{
        padding: 20px;
    }

    td:last-child{
        padding-right: 20px;
    }

    th:first-child,
    td:first-child{
        width: 90%;
    }

    td{
        padding: 10px 5px;
        border-bottom: 1px solid #F2F3F6;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        position: relative;
    }

    tr:last-child td{
        border-bottom: none;
    }

    .clients__name{
        position: relative;
    }

    @media(max-width:560px){
        .clients__wrp{
            padding: 10px 15px;;
        }
    }

    .bg{
        width: 100vw;
        height: 100vh;
        background-color: #3d3d3d;
        opacity: 0.5;
        z-index:  14;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>